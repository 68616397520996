// Preloader
//$(window).on('load', function () {
//	if ($('#preloader').length) {
//		$('#preloader').delay(100).fadeOut('slow', function () {
//			$(this).remove();
//		});
//	}
//});
$(document).ready(function() {
	// Header scroll class
	$(window).scroll(function() {
		if ($(this).scrollTop() > 100) {
			$('#header').addClass('header-scrolled');
		} else {
			$('#header').removeClass('header-scrolled');
		}
	});
	// Back to top button
		$(window).scroll(function() {
			if ($(this).scrollTop() > 100) {
				$('.back-to-top').fadeIn('slow');
			} else {
				$('.back-to-top').fadeOut('slow');
			}
	});
	$('.back-to-top').click(function(){
		$('html, body').animate({scrollTop : 0},1500, 'easeInOutExpo');
		return false;
	});
	// Inicializa the wowjs animation library
  	new WOW().init();
	// Inicializa superfish on nav menu
	$('.nav-menu').superfish({
		animation: {
			opacity: 'show'
		},
		speed: 400
	});
	// Mobile Navegação
	if ($('#nav-menu-container').length) {
		var $mobile_nav = $('#nav-menu-container').clone().prop({
			id: 'mobile-nav'
		});
		$mobile_nav.find('> ul').attr({
			'class': '',
			'id': ''
		});
		$('body').append($mobile_nav);
		$('body').prepend('<button type="button" id="mobile-nav-toggle"><i class="fa fa-bars"></i></button>');
		$('body').append('<div id="mobile-body-overly"></div>');
		$('#mobile-nav').find('.menu-has-children').prepend('<i class="fa fa-chevron-down"></i>');
		$(document).on('click', '.menu-has-children i', function(e) {
			$(this).next().toggleClass('menu-item-active');
			$(this).nextAll('ul').eq(0).slideToggle();
			$(this).toggleClass("fa-chevron-up fa-chevron-down");
		});
		$(document).on('click', '#mobile-nav-toggle', function(e) {
			$('body').toggleClass('mobile-nav-active');
			$('#mobile-nav-toggle i').toggleClass('fa-times fa-bars');
			$('#mobile-body-overly').toggle();
		});
		$(document).click(function(e) {
			var container = $("#mobile-nav, #mobile-nav-toggle");
			if (!container.is(e.target) && container.has(e.target).length === 0) {
				if ($('body').hasClass('mobile-nav-active')) {
					$('body').removeClass('mobile-nav-active');
					$('#mobile-nav-toggle i').toggleClass('fa-times fa-bars');
					$('#mobile-body-overly').fadeOut();
				}
			}
		});
	} else if ($("#mobile-nav, #mobile-nav-toggle").length) {
		$("#mobile-nav, #mobile-nav-toggle").hide();
	}
	// Header scroll class
	$(window).scroll(function() {
		if ($(this).scrollTop() > 100) {
			$('#header').addClass('header-scrolled');
		} else {
			$('#header').removeClass('header-scrolled');
		}
	});
	if ($(window).scrollTop() > 100) {
		$('#header').addClass('header-scrolled');
	}
	// Smooth scroll for the menu and links with .scrollto classes
	$('.nav-menu a, #mobile-nav a, .scrollto').on('click', function() {
		if (location.pathname.replace(/^\//, '') == this.pathname.replace(/^\//, '') && location.hostname == this.hostname) {
			var target = $(this.hash);
			if (target.length) {
				var top_space = 0;
				if ($('#header').length) {
					top_space = $('#header').outerHeight();
					if (! $('#header').hasClass('header-scrolled')) {
						top_space = top_space - 20;
					}
				}
				$('html, body').animate({
					scrollTop: target.offset().top - top_space
				}, 1500, 'easeInOutExpo');
				if ($(this).parents('.nav-menu').length) {
					$('.nav-menu .menu-active').removeClass('menu-active');
					$(this).closest('li').addClass('menu-active');
				}
				if ($('body').hasClass('mobile-nav-active')) {
					$('body').removeClass('mobile-nav-active');
					$('#mobile-nav-toggle i').toggleClass('fa-times fa-bars');
					$('#mobile-body-overly').fadeOut();
				}
				return false;
			}
		}
	});
	// Navegação ativa state on scroll
	var nav_sections = $('section');
	var main_nav = $('.nav-menu, #mobile-nav');
	var main_nav_height = $('#header').outerHeight();
	$(window).on('scroll', function () {
		var cur_pos = $(this).scrollTop();
		nav_sections.each(function() {
			var top = $(this).offset().top - main_nav_height,
			bottom = top + $(this).outerHeight();
			if (cur_pos >= top && cur_pos <= bottom) {
				main_nav.find('li').removeClass('menu-active menu-item-active');
				main_nav.find('a[href="#'+$(this).attr('id')+'"]').parent('li').addClass('menu-active menu-item-active');
			}
		});
	});
	// Intro carousel
	var introCarousel = $(".carousel");
	var introCarouselIndicators = $(".carousel-indicators");
	introCarousel.find(".carousel-inner").children(".carousel-item").each(function(index) {
		(index === 0) ?
			introCarouselIndicators.append("<li data-target='#introCarousel' data-slide-to='" + index + "' class='active'></li>") :
			introCarouselIndicators.append("<li data-target='#introCarousel' data-slide-to='" + index + "'></li>");

		$(this).css("background-image", "url('" + $(this).children('.carousel-background').children('img').attr('src') +"')");
		$(this).children('.carousel-background').remove();
	});
	$(".carousel").swipe({
		swipe: function(event, direction, distance, duration, fingerCount, fingerData) {
			if (direction == 'left') $(this).carousel('next');
			if (direction == 'right') $(this).carousel('prev');
		},
		allowPageScroll:"vertical"
	});
	// Atuação filter
	var atuacaoIsotope = $('.atuacao-container').isotope({
		itemSelector: '.atuacao-item',
		layoutMode: 'fitRows'
	});
	$('#atuacao-flters li').on( 'click', function() {
		$('.atuacao-container').removeClass('style-none');
		$("#atuacao-flters li").removeClass('filter-active');
		$(this).addClass('filter-active');
		atuacaoIsotope.isotope({ filter: $(this).data('filter') });
	});
	// Download isotope and filter
	var downloadIsotope = $('.download-container').isotope({
		itemSelector: '.download-item',
		layoutMode: 'fitRows'
	});
	$('#download-flters li').on( 'click', function() {
		$("#download-flters li").removeClass('filter-active');
		$(this).addClass('filter-active');
		downloadIsotope.isotope({ filter: $(this).data('filter') });
	});
	// Clients carousel (uses the Owl Carousel library)
	$(".clients-carousel").owlCarousel({
		autoplay: true,
		dots: true,
		loop: true,
		responsive: {
			0: { items: 2 }, 768: { items: 4 }, 900: { items: 6 }
		}
	});
	$('.clear-box').on('keypress', function() {
		$("#errormessage").removeClass("show");
		$("#sendmessage").removeClass("show");
	});
	$('#btn-envia-form').on('click', function() {
		$('#btn-envia-form').html('Enviando <i class="fa fa-spinner fa-spin fa-fw"></i>');
		$('#btn-envia-form').prop('disabled', true);
		var testEmail = /^[A-Z0-9._%+-]+@([A-Z0-9-]+\.)+[A-Z]{2,4}$/i;
		if (!testEmail.test($('#email').val())) {
			$('#btn-envia-form').html('Enviar');
			$('#btn-envia-form').prop('disabled', false);
		}
	});
	// Validação formulário contato
	$('form.contactForm').submit(function(e) {
		var url = $(this).attr("action");
		var msg = '';
		var valor_um = Math.floor(Math.random() * 11);
        var valor_dois = Math.floor(Math.random() * 11);
        var total = valor_um + valor_dois;
        var result = prompt("Não sou robô, quanto é: " + valor_um + " + " + valor_dois + "?", "");
        if (result) {
            if (total != result) {
                msg = 'Valor inválido.<br>'
            }
        } else {
            msg = 'Valor inválido.<br>'
        }

		if ($('#name').val() == '') {
			msg += 'Por favor informe seu nome.<br>';
		}
		if ($('#email').val() == '') {
			msg += 'Por favor informe seu email.<br>';
		}
		if ($('#subject').val() == '') {
			msg += 'Por favor informe o assunto.<br>';
		}
		if ($('#message').val() == '') {
			msg += 'Por favor informe uma mensagem.';
		}
		if (msg != '') {
			$("#sendmessage").removeClass("show");
			$("#errormessage").addClass("show");
			$('#errormessage').html(msg);
			$('#btn-envia-form').html('Enviar');
			$('#btn-envia-form').prop('disabled', false);
			return false;
		}
		var form = $('.contactForm').serialize();
		$.ajax({
			type: "POST",
			url: url,
			data: form,
			success: function(dados) {
				if (dados.status == 'danger') {
					$("#sendmessage").removeClass("show");
					$("#errormessage").addClass("show");
					$('#errormessage').html(dados.message);
					$('#btn-envia-form').html('Enviar');
					$('#btn-envia-form').prop('disabled', false);
				} else {
					$("#sendmessage").addClass("show");
					$('#sendmessage').html(dados.message);
					$('#sendmessage').delay(8500).fadeOut('slow', function () {
						$(this).remove();
					});
					$("#errormessage").removeClass("show");
					$('.contactForm').find("input, textarea").val("");
					$('#btn-envia-form').html('Enviar');
					$('#btn-envia-form').prop('disabled', false);
				}
			},
			error: function() {
				$("#sendmessage").removeClass("show");
				$("#errormessage").addClass("show");
				$('#errormessage').html('Erro no envio da mensagem, por favor tente mais tarde.');
				$('#errormessage').delay(10500).fadeOut('slow', function () {
					$(this).remove();
				});
				$('#btn-envia-form').html('Enviar');
				$('#btn-envia-form').prop('disabled', false);
			}
		});
		return false;
	});
	$('#phone').mask(mascara, options);

document.getElementById('pills-anual-tab').addEventListener('click', function() {
    document.querySelector('.preco-gestao-2').textContent = '175,80';
    document.querySelector('.preco-gestao-1').textContent = '149,70';
    document.querySelector('.preco-emissao').textContent = '99,70';
    toggleOldPrecoEmissaoVisibility(true);
});

document.getElementById('pills-semestral-tab').addEventListener('click', function() {
    document.querySelector('.preco-gestao-2').textContent = '182,70';
    document.querySelector('.preco-gestao-1').textContent = '157,70';
    document.querySelector('.preco-emissao').textContent = '107,70';
    toggleOldPrecoEmissaoVisibility(true);
});

document.getElementById('pills-mensal-tab').addEventListener('click', function() {
    document.querySelector('.preco-gestao-2').textContent = '194,70';
    document.querySelector('.preco-gestao-1').textContent = '169,70';
    document.querySelector('.preco-emissao').textContent = '119,70';
    toggleOldPrecoEmissaoVisibility(false);
});


function toggleOldPrecoEmissaoVisibility(visible) {
    var oldPrecogestao2 = document.getElementById('old-preco-gestao-2') || document.getElementsByClassName('old-preco-gestao-2')[0];
    if (oldPrecogestao2) {
        oldPrecogestao2.style.display = visible ? 'block' : 'none';
    }
    var oldPrecogestao1 = document.getElementById('old-preco-gestao-1') || document.getElementsByClassName('old-preco-gestao-1')[0];
    if (oldPrecogestao1) {
        oldPrecogestao1.style.display = visible ? 'block' : 'none';
    }
    var oldPrecoEmissao = document.getElementById('old-preco-emissao') || document.getElementsByClassName('old-preco-emissao')[0];
    if (oldPrecoEmissao) {
        oldPrecoEmissao.style.display = visible ? 'block' : 'none';
    }
}

});
var mascara = function(val) {
    return val.replace(/[^\w\s]/gi, "").length === 11 ? "(00) 0000-00009" : "(00) 00000-0000";
},
options = {
    onKeyPress: function(val, e, field, options) {
        field.mask(mascara.apply({}, arguments), options);
    }
};

